import React from "react";
import TagListEditor from "../components/tags/TagListEditor";
import ArtistListEditor from "../components/artist/ArtistListEditor";

function AdminTagsArtists() {
  return (
    <div className="tags-artrist-admin-page">
      <h2>Page for managing tags and artists</h2>
      <TagListEditor />
      <ArtistListEditor />
    </div>
  );
}
export default AdminTagsArtists;
