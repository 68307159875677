import AlbumCard from "../components/album/Album";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiRust } from "../api";
import { useApp } from "../contexts/AppContext";
import { toast } from "react-toastify";

const AlbumPage = () => {
  const { cid } = useParams();
  const { albums, setAlbums } = useApp();
  const [stateAlbum, setStateAlbum] = useState();
  // console.log(albums);
  const getAlbum = async (cid) => {
    try {
      const response = await apiRust.get(`/albums/${cid}`);
      setStateAlbum(response.data);
    } catch (error) {
      toast.error("Error updating album");
      console.error("Error updating album", error);
    }
  };
  useEffect(() => {
    const album = albums.find((obj) => obj.cid == cid);
    if (album !== undefined) {
      if (album.songs !== undefined) {
        setStateAlbum(album);
      } else {
        getAlbum(cid);
      }
    } else {
      getAlbum(cid);
    }
  }, []);
  const albumUpdateCallBack = async (cid) => {
    try {
      const response = await apiRust.get(`/albums/${cid}`);
      setStateAlbum(response.data);
      const updatedAlbum = albums.map((obj) =>
        obj.cid === cid ? { ...obj, ...response.data } : obj,
      );
      setAlbums(updatedAlbum);
    } catch (error) {
      toast.error("Error updating album");
      console.error("Error updating album", error);
    }
  };

  return (
    <div className="album-page">
      {stateAlbum && (
        <AlbumCard
          album={stateAlbum}
          albumUpdateCallBack={albumUpdateCallBack}
          searchMode={true}
        />
      )}
    </div>
  );
};

export { AlbumPage };
