import React from "react";
import { Outlet } from "react-router-dom";
// import Footer from "../footer/footer";
import Player from "../player/Player";
import Header from "../header/Header";
import { usePlayer } from "../../contexts/PlayerContext";

const Layout = () => {
  const { playedSong } = usePlayer();
  // : <Footer />
  return (
    <div className="app-container">
      <Header />
      <div className="app" id="app">
        <Outlet className="Outlet" />
      </div>
      {playedSong && playedSong.name && <Player />}
    </div>
  );
};

export default Layout;
