import React, { useState } from "react";
import { apiRust } from "../../api";
import Artist from "../artist/Artist";
import TagsAdmin from "../tags/Tags";
import Modal from "../../ui/Modal/Modal";
import ChangesForm from "../ChangeRequesForm/ChangeRequestForm";
import { toast } from "react-toastify";
import st from "./SongEdit.module.css";

import { useAuth } from "../../contexts/AuthContext";
import PlayButton from "../../ui/PlayButton/PlayButton";
import EditButton from "../../ui/EditButton/EditButton";
import SelectedSongIco from "../../ui/SelectedSongIco/SelectedSongIco";

const addSongTagRelation = async (songId, tagId) => {
  try {
    console.log("add", songId, tagId);
    await apiRust.post("/relations/song-tag", {
      song_cid: songId,
      tag_cid: tagId,
    });
  } catch (error) {
    console.error("Ошибка при связывании песни и тега", error);
  }
};
const removeSongTagRelation = async (songId, tagId) => {
  try {
    console.log("delete/", songId, tagId);

    await apiRust.delete(
      `/relations/song-tag?song_cid=${songId}&tag_cid=${tagId}`,
    );
  } catch (error) {
    console.error("Ошибка при отвязывания песни и тега", error);
  }
};
const addSongArtistRelation = async (songId, artistId) => {
  try {
    console.log("add song-artist", songId, artistId);
    await apiRust.post("/relations/song-artist", {
      song_cid: songId,
      artist_cid: artistId,
    });
  } catch (error) {
    console.error("Ошибка при связывании песни и артиста", error);
  }
};
const removeSongArtistRelation = async (songId, artistId) => {
  try {
    await apiRust.delete(
      `/relations/song-artist?song_cid=${songId}&artist_cid=${artistId}`,
    );
  } catch (error) {
    console.error("Ошибка при отвязывании песни и артиста", error);
  }
};

const processTagsAndArtists = async (
  songCid,
  addedTags,
  removedTags,
  addedArtists,
  removedArtists,
) => {
  const processPromises = [];

  for (const tag of addedTags) {
    processPromises.push(addSongTagRelation(songCid, tag.cid));
  }

  for (const tag of removedTags) {
    processPromises.push(removeSongTagRelation(songCid, tag.cid));
  }

  for (const artist of addedArtists) {
    processPromises.push(addSongArtistRelation(songCid, artist.cid));
  }

  for (const artist of removedArtists) {
    processPromises.push(removeSongArtistRelation(songCid, artist.cid));
  }

  // Дожидаемся выполнения всех асинхронных операций
  await Promise.all(processPromises);
};
const SongEdit = ({
  song,
  index,
  selected,
  updateCallBack,
  handleSetSong,
  played,
  editMode,
  setEditMode,
}) => {
  const { isAdmin } = useAuth();
  const [artistsValue, setArtistsValue] = useState([]);
  const [modalChangesVisible, setModalChangesVisible] = useState(false);
  const [tagsValue, setTagsValue] = useState([]);
  const prepareChanges = () => {
    const addedTags = tagsValue.filter(
      (tag) => !song.tags.some((originalTag) => originalTag.cid === tag.cid),
    );
    const removedTags = song.tags.filter(
      (originalTag) => !tagsValue.some((tag) => tag.cid === originalTag.cid),
    );

    const addedArtists = artistsValue.filter(
      (artist) =>
        !song.artists.some(
          (originalArtist) => originalArtist.cid === artist.cid,
        ),
    );
    const removedArtists = song.artists.filter(
      (originalArtist) =>
        !artistsValue.some((artist) => artist.cid === originalArtist.cid),
    );

    const result = {
      addedTags,
      removedTags,
      addedArtists,
      removedArtists,
    };

    return result;
  };
  const handleSaveChanges = async (e) => {
    // Обработка добавленных и удаленных тегов и артистов
    e.stopPropagation();
    const changes = prepareChanges();
    console.log(changes);
    await processTagsAndArtists(
      song.cid,
      changes.addedTags,
      changes.removedTags,
      changes.addedArtists,
      changes.removedArtists,
    );

    // console.log("Измененная песня:", editedSong);
    await updateCallBack(song.cid);
    toast.success("Save changes!");
    setEditMode(false);
  };

  return (
    <div className={`${st.songContainer} ${selected ? st.selected : ""}`}>
      <div className={st.playButton} onClick={handleSetSong}>
        <PlayButton played={played} />
      </div>
      {selected & played ? (
        <div className={st.selectedSongIco}>
          <SelectedSongIco />
        </div>
      ) : (
        <div className={st.songNumber}>{index + 1}</div>
      )}
      <strong className={st.title}>Title: {song.name}</strong>
      <div className={st.artists}>
        <Artist
          artists={song.artists}
          setParentArtistsValue={setArtistsValue}
        />
      </div>
      <div className={st.tags}>
        <TagsAdmin tags={song.tags} setParentTagsValue={setTagsValue} />
      </div>
      <div className={st.editButton}>
        <EditButton editMode={editMode} setEditMode={setEditMode} />
      </div>
      {isAdmin ? (
        <div className={st.confirm}>
          <button className="button" onClick={handleSaveChanges}>
            Save
          </button>
        </div>
      ) : (
        <>
          {modalChangesVisible && (
            <Modal
              visible={modalChangesVisible}
              setVisible={setModalChangesVisible}
              children={
                <ChangesForm
                  changes={prepareChanges()}
                  song={song}
                  setVisible={setModalChangesVisible}
                  setChangeMode={setEditMode}
                />
              }
            />
          )}
          <div className={st.confirm}>
            <button
              className="button"
              onClick={() => {
                setModalChangesVisible(true);
              }}
            >
              Request Changes
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SongEdit;
