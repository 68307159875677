import React from "react";
import OfferItem from "./OfferItem";

const OfferList = ({ offers, onConfirm, onReject }) => {
  // const offer = offers.length > 0 ? offers[0] : null;

  return (
    <div className="offer-table">
      <div>
        {offers.map((offer) => (
          <OfferItem
            key={offer.cid}
            offer={offer}
            onConfirm={onConfirm}
            onReject={onReject}
          />
        ))}
      </div>
    </div>
  );
};

export default OfferList;
