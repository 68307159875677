import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { apiRust } from "../../api";
import { useApp } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

const createTagOption = (tag) => ({
  label: tag.tag,
  value: tag.cid,
});
const restore = (tag) => ({
  tag: tag.label,
  cid: tag.value,
});

const TagsEdit = ({ tags, setParentTagsValue }) => {
  const { isAdmin } = useAuth();
  const { tagList, setTagList } = useApp();
  const tagsOpts = tags.map((tag) => createTagOption(tag));
  const [isLoading, setIsLoading] = useState(false);
  // const [tagList, setTagList] = useState(tagsOpts);
  const [tagsValue, setTagsValue] = useState(tagsOpts);
  useEffect(() => {
    setParentTagsValue(tagsValue.map((value) => restore(value)));
  }, []);

  const handleAddNewTag = async (inputValue) => {
    if (isAdmin) {
      try {
        setIsLoading(true);
        const response = await apiRust.post("/tags", {
          tag: inputValue,
        });
        setIsLoading(false);
        const newTagOption = createTagOption(response.data);
        setTagList((prev) => [...prev, newTagOption]);
        setTagsValue((prev) => [...prev, newTagOption]);
        setParentTagsValue((prev) => [...prev, response.data]);
        toast.success(`Tag ${inputValue} added to database`);
      } catch (error) {
        toast.error(`Error when adding`);
        console.error("Ошибка при добавлении нового тега", error);
      }
    } else {
      const tag = { tag: inputValue, cid: null };
      const newTagOption = createTagOption(tag);
      setTagList((prev) => [...prev, newTagOption]);
      setTagsValue((prev) => [...prev, newTagOption]);
      setParentTagsValue((prev) => [...prev, tag]);
    }
  };

  return (
    <div>
      <strong>Tags: </strong>
      <div className="multiselect">
        <CreatableSelect
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "inherit",
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: "#D9D9D9",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: "#262626",
            }),
          }}
          isClearable
          isMulti
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={(newValue) => {
            setTagsValue(newValue);
            setParentTagsValue(newValue.map((value) => restore(value)));
          }}
          onCreateOption={handleAddNewTag}
          options={tagList}
          value={tagsValue}
        />
      </div>
    </div>
  );
};

export default TagsEdit;
