import EditSvg from "../../assets/edit.svg";
import CloseSvg from "../../assets/close.svg";
import st from "./EditButton.module.css";

const EditButton = ({ editMode, setEditMode }) => {
  return (
    <div className={st.svgButton}>
      {editMode ? (
        <img onClick={() => setEditMode(false)} src={CloseSvg} alt="edit" />
      ) : (
        <img onClick={() => setEditMode(true)} src={EditSvg} alt="edit" />
      )}
    </div>
  );
};
export default EditButton;
