import React, { useState, useRef, useEffect } from "react";
import { apiRust } from "../../api";
import Artist from "../artist/Artist";
import TagsAdmin from "../tags/Tags";
import { useAuth } from "../../contexts/AuthContext";
import Modal from "../../ui/Modal/Modal";
import AlbumChangesForm from "../ChangeRequesForm/AlbumChangeRequestForm";
import { toast } from "react-toastify";
import st from "./Album.module.css";
import ListItem from "../ListItems/ListItems";
import EditButton from "../../ui/EditButton/EditButton";
import SongControler from "../song/SongController";
import { usePlayer } from "../../contexts/PlayerContext";
import PlayButton from "../../ui/PlayButton/PlayButton";

const addAlbumTagRelation = async (albumId, tagId) => {
  try {
    await apiRust.post("/relations/album-tag", {
      album_cid: albumId,
      tag_cid: tagId,
    });
  } catch (error) {
    console.error("Ошибка при связывании альбома и тега", error);
  }
};
const removeAlbumTagRelation = async (albumId, tagId) => {
  try {
    await apiRust.delete(
      `/relations/album-tag?album_cid=${albumId}&tag_cid=${tagId}`,
    );
  } catch (error) {
    console.error("Ошибка при отвязывания альбома и тега", error);
  }
};
const addAlbumArtistRelation = async (albumId, artistId) => {
  try {
    await apiRust.post("/relations/album-artist", {
      album_cid: albumId,
      artist_cid: artistId,
    });
  } catch (error) {
    console.error("Ошибка при связывании альбома и артиста", error);
  }
};
const removeAlbumArtistRelation = async (albumId, artistId) => {
  try {
    await apiRust.delete(
      `/relations/album-artist?album_cid=${albumId}&artist_cid=${artistId}`,
    );
  } catch (error) {
    console.error("Ошибка при отвязывании альбома и артиста", error);
  }
};

const processTagsAndArtists = async (
  albumCid,
  addedTags,
  removedTags,
  addedArtists,
  removedArtists,
) => {
  const processPromises = [];

  for (const tag of addedTags) {
    processPromises.push(addAlbumTagRelation(albumCid, tag.cid));
  }

  for (const tag of removedTags) {
    processPromises.push(removeAlbumTagRelation(albumCid, tag.cid));
  }

  for (const artist of addedArtists) {
    processPromises.push(addAlbumArtistRelation(albumCid, artist.cid));
  }

  for (const artist of removedArtists) {
    processPromises.push(removeAlbumArtistRelation(albumCid, artist.cid));
  }

  // Дожидаемся выполнения всех асинхронных операций
  await Promise.all(processPromises);
};

const AlbumCard = ({ album, albumUpdateCallBack }) => {
  const [songs, setSongs] = useState([]);
  const lastSongs = useRef([]);
  const { isAdmin, isVerified } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [artistsValue, setArtistsValue] = useState([]);
  const [tagsValue, setTagsValue] = useState([]);
  const [modalChangesVisible, setModalChangesVisible] = useState(false);
  const { playSongByCid, replacePlaylist, paused, setPaused, playedSong } =
    usePlayer();

  // const albumPlayMenu = `albumPlayMenu${album.cid}`;
  // const { show } = useContextMenu({
  //   id: albumPlayMenu,
  // });
  // function displayMenu(e) {
  //   show({
  //     event: e,
  //   });
  // }
  const [localPaused, setLocalPaused] = useState(true);
  const songInThisAlbum = () => {
    return album.songs.some((obj) => obj.cid === playedSong.cid);
  };
  const checkPlayedSongRelationToThisAlbum = () => {
    if (playedSong) {
      if (songInThisAlbum()) {
        setLocalPaused(paused);
      } else {
        setLocalPaused(true);
      }
    }
  };
  useEffect(() => {
    checkPlayedSongRelationToThisAlbum();
  }, [playedSong, paused]);
  const pauseOrAddNewPlalist = () => {
    const check = songInThisAlbum();
    if (check & !paused) {
      setPaused(true);
    } else if (check) {
      setPaused(!paused);
    } else {
      replacePlaylist(album.songs);
    }
  };

  const setPlayListAndPlaySongWithCid = (index) => {
    // const songIndex = album.songs.findIndex((song) => song.cid === cid);
    replacePlaylist(album.songs, index);
    // playSongByCid(cid);
  };

  useEffect(() => {
    if (lastSongs.current !== album.songs) {
      // Обновляем список песен
      setSongs(album.songs || []);
    }
  }, [lastSongs, album.songs]);
  const songsUpdateCallBack = async (songCid) => {
    try {
      const response = await apiRust.get(`/song/${songCid}`);
      const updatedSongs = songs.map((obj) =>
        obj.cid === songCid ? { ...obj, ...response.data } : obj,
      );
      // console.log(updatedSongs);
      setSongs(updatedSongs);
      // console.log(songs);
    } catch (error) {
      console.error(error);
    }
  };
  const prepareChanges = () => {
    const addedTags = tagsValue.filter(
      (tag) => !album.tags.some((originalTag) => originalTag.cid === tag.cid),
    );
    const removedTags = album.tags.filter(
      (originalTag) => !tagsValue.some((tag) => tag.cid === originalTag.cid),
    );

    const addedArtists = artistsValue.filter(
      (artist) =>
        !album.artists.some(
          (originalArtist) => originalArtist.cid === artist.cid,
        ),
    );
    const removedArtists = album.artists.filter(
      (originalArtist) =>
        !artistsValue.some((artist) => artist.cid === originalArtist.cid),
    );

    const result = {
      addedTags,
      removedTags,
      addedArtists,
      removedArtists,
    };

    return result;
  };
  const handleSaveChanges = async () => {
    // Обработка добавленных и удаленных тегов и артистов
    const changes = prepareChanges();
    console.log(changes);
    await processTagsAndArtists(
      album.cid,
      changes.addedTags,
      changes.removedTags,
      changes.addedArtists,
      changes.removedArtists,
    );

    await albumUpdateCallBack(album.cid);
    toast.success("Save changes!");
    setEditMode(false);
  };
  return (
    <div className={st.albumCard}>
      <div className={st.coverImage}>
        <img src={album.cover_url} alt={album.name} />
      </div>
      <div className={st.albumHeader}>
        <strong className={st.albumName}>{album.name}</strong>
        <div className={st.intro}>Intro: {album.intro}</div>
      </div>

      {isAdmin ? (
        <div className={st.EditSvg}>
          <EditButton editMode={editMode} setEditMode={setEditMode} />
        </div>
      ) : (
        <>
          {/* <> */}
          {/*   {!isAdmin && !isVerified && ( */}
          {/*     <span title="Only users with verified email can request changes."> */}
          {/*       <div className={st.EditSvg}> */}
          {/*         <EditButton editMode={false} setEditMode={() => { }} /> */}
          {/*       </div>{" "} */}
          {/*     </span> */}
          {/*   )} */}
          {/* </> */}
          {/* <> */}
          {/*   {!isAdmin && isVerified && ( */}
          {/*     <div className={st.EditSvg}> */}
          {/*       <EditButton editMode={editMode} setEditMode={setEditMode} /> */}
          {/*     </div> */}
          {/*   )} */}
          {/* </> */}
        </>
      )}
      <div className={st.albumContent}>
        {editMode ? (
          <div className="edit-content">
            <Artist
              artists={album.artists}
              setParentArtistsValue={setArtistsValue}
            />
            <TagsAdmin tags={album.tags} setParentTagsValue={setTagsValue} />
            {isAdmin ? (
              <div className={st.confirm}>
                <button className="button" onClick={handleSaveChanges}>
                  Save
                </button>
              </div>
            ) : (
              <>
                {modalChangesVisible && (
                  <Modal
                    visible={modalChangesVisible}
                    setVisible={setModalChangesVisible}
                    children={
                      <AlbumChangesForm
                        changes={prepareChanges()}
                        album={album}
                        setVisible={setModalChangesVisible}
                        setChangeMode={editMode}
                      />
                    }
                  />
                )}
                <div className={st.confirm}>
                  <button
                    className="button"
                    onClick={() => {
                      setModalChangesVisible(true);
                    }}
                  >
                    Request Changes
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={st.albumArtistTagContainer}>
            <ListItem items={album.artists} label="Artists" />
            <ListItem items={album.tags} label="Tags" />
          </div>
        )}
        {songs.length > 0 && (
          <>
            <div
              className={st.addAlbumToPlalistButton}
              onClick={pauseOrAddNewPlalist}
            >
              <PlayButton played={!localPaused} />
              <div>Play all</div>
            </div>

            <div className={st.songList}>
              {songs.map((song, index) => (
                <SongControler
                  song={song}
                  key={song.cid}
                  index={index}
                  setPlayListAndPlaySongWithCid={setPlayListAndPlaySongWithCid}
                  updateCallBack={songsUpdateCallBack}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default AlbumCard;
