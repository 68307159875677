import { createContext, useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

const PlayerContext = createContext();

const PlayerProvider = ({ children }) => {
  const [playList, setPlayList] = useState(
    JSON.parse(localStorage.getItem("playlist")) || [],
  );
  const [playedSong, setPlayedSong] = useState(
    JSON.parse(localStorage.getItem("playedsong")) || [],
  );
  const [paused, setPaused] = useState(true);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  useEffect(() => {
    localStorage.setItem("playlist", JSON.stringify(playList));
  }, [playList]);

  useEffect(() => {
    localStorage.setItem("playedsong", JSON.stringify(playedSong));
  }, [playedSong]);

  const addToPlaylist = (song) => {
    setPlayList([...playList, song]);
    toast.success(`Song ${song.name} added to playlist`, {
      autoClose: 1000,
      theme: "dark",
    });
  };

  const clearPlaylist = () => {
    if (playedSong) {
      setPlayList([playedSong]);
    } else {
      setPlayList([]);
    }
    setCurrentSongIndex(0);
  };

  const replacePlaylistWithOne = (song) => {
    setPlayList([song]);
    setPlayedSong(song);
    setPaused(false);
    setCurrentSongIndex(0);
  };

  const replacePlaylist = (newPlaylist, songIndex = 0) => {
    if (newPlaylist != playList) {
      setPlayList(newPlaylist);
      setPlayedSong(newPlaylist[songIndex]);
      setPaused(false);
      setCurrentSongIndex(songIndex);
    } else {
      setPaused(false);
      setPlayedSong(playList[songIndex]);
      setCurrentSongIndex(songIndex);
    }
  };
  const playNextSong = () => {
    if (currentSongIndex < playList.length - 1) {
      setCurrentSongIndex(currentSongIndex + 1);
      setPlayedSong(playList[currentSongIndex + 1]);
      setPaused(false);
    }
  };

  const playPreviousSong = () => {
    if (currentSongIndex > 0) {
      setCurrentSongIndex(currentSongIndex - 1);
      setPlayedSong(playList[currentSongIndex - 1]);
    }
  };

  const removeFromPlaylist = (index) => {
    const updatedPlaylist = [...playList];
    updatedPlaylist.splice(index, 1);

    if (index === currentSongIndex) {
      // Если удаляемый элемент - текущий проигрываемый, обновляем проигрываемую песню и паузу
      const newCurrentIndex = Math.min(index, updatedPlaylist.length - 1);
      setPlayedSong(updatedPlaylist[newCurrentIndex]);
      setCurrentSongIndex(newCurrentIndex);
      setPaused(true);
    } else {
      // Иначе просто обновляем плейлист
      setPlayList(updatedPlaylist);
    }

    toast.success(`Song removed from playlist`, {
      autoClose: 1000,
      theme: "dark",
    });
  };
  const playSongByCid = (cid) => {
    console.log("song cid", cid);
    const index = playList.findIndex((song) => song.cid === cid);

    console.log("song index", index);
    if (index !== -1) {
      setPlayedSong(playList[index]);
      setCurrentSongIndex(index);
      setPaused(false);
    } else {
      // Если песня с заданным cid не найдена в плейлисте
      console.error(`Song with cid ${cid} not found in the playlist.`);
    }
  };
  return (
    <PlayerContext.Provider
      value={{
        playList,
        playedSong,
        currentSongIndex,
        setCurrentSongIndex,
        setPlayedSong,
        addToPlaylist,
        clearPlaylist,
        replacePlaylist,
        replacePlaylistWithOne,
        removeFromPlaylist,
        playNextSong,
        playPreviousSong,
        playSongByCid,
        paused,
        setPaused,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error("useApp must be used within an AuthProvider");
  }
  return context;
};

export { PlayerProvider, usePlayer };
