import React from "react";
import SongOnPlayList from "../../components/song/SongOnPlayList";
import { usePlayer } from "../../contexts/PlayerContext";
import DeleteButton from "../../ui/DeleteButton/DeleteButton";
import st from "./Queue.module.css";

const QueuePage = () => {
  const { playList, currentSongIndex, clearPlaylist } = usePlayer();
  return (
    <div className={st.queuePage}>
      <h1>Queue</h1>
      {playList.length > 0 ? (
        <>
          <h2>Now playing</h2>
          <div>
            <SongOnPlayList
              song={playList[currentSongIndex]}
              index={currentSongIndex}
            />
          </div>
          <div className={st.queueListContainer}>
            <h2 className={st.next}>Next in queue</h2>
            {playList.length > 1 && (
              <div className={st.deleteButton} onClick={clearPlaylist}>
                <DeleteButton />
              </div>
            )}
            <div className={st.queueList}>
              {playList.map((song, index) => {
                if (index !== currentSongIndex) {
                  return <SongOnPlayList song={song} index={index} />;
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        </>
      ) : (
        <h2>No tracks in queue</h2>
      )}
    </div>
  );
};

export default QueuePage;
