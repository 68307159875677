import React, { useState } from "react";
import { apiRust } from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RegistrationComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [registrationResult, setRegistrationResult] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const isPasswordValid = () => {
    // Базовая проверка на простоту (длина не менее 6 символов)
    return password.length >= 6;
  };

  const doPasswordsMatch = () => {
    return password === confirmPassword;
  };

  const handleRegistration = async () => {
    try {
      if (!isPasswordValid()) {
        throw new Error(
          "The password is too short. Minimum length: 6 characters.",
        );
      }

      if (!doPasswordsMatch()) {
        throw new Error("Password mismatch.");
      }

      const response = await apiRust.post("/auth/register", {
        email,
        password,
      });
      toast.success(`Registration complete: ${response.data.email}`);
      navigate("/login");
    } catch (error) {
      // if (error.response && error.response.data && error.response.data.detail) {
      // // Обработка ошибок валидации от сервера
      // const validationErrors = error.response.data.detail;
      // const errorMessage = validationErrors
      //   .map((errorItem) => errorItem.msg)
      //   .join(", ");
      // toast.error(`Error during registration: ${errorMessage}`);
      // } else {
      // Обработка других ошибок
      console.log(error);
      const errorMsg =
        error.response.data.detail[0].msg ||
        error.response.data.detail ||
        "An unknown error has occurred";
      toast.error(`Error during registration: ${errorMsg}`);
      // }
    }
  };
  return (
    <div className="centered-form">
      <div className="form-container">
        <h2>Registration</h2>
        <form>
          <div className="form-group">
            <label htmlFor="email"></label>
            <input
              id="email"
              placeholder="Email"
              className="input"
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password"></label>
            <input
              id="password"
              type="password"
              placeholder="Password"
              className="input"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword"></label>
            <input
              id="confirmPassword"
              placeholder="Confirm password"
              type="password"
              className="input"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>

          <div className="form-group">
            <button
              className="button"
              type="button"
              onClick={handleRegistration}
            >
              Register
            </button>
          </div>
        </form>
        {/* {registrationResult && ( */}
        {/*   <div> */}
        {/*     <h3>{registrationResult}</h3> */}
        {/*   </div> */}
        {/* )} */}
      </div>
    </div>
  );
};

export default RegistrationComponent;
