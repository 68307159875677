import React, { useState } from "react";

const OfferItem = ({ offer, onConfirm, onReject }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleToggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  return (
    <div className="offer-item-container">
      <div className="offer-item">
        <div className="offer-click-container" onClick={handleToggleDetails}>
          <div>Email: {offer.user.email}</div>
          {offer.album && <div>Album: {offer.album.name}</div>}
          {offer.song && <div>Song: {offer.song.name}</div>}
          {offer.artist && <div>Artsit: {offer.artist}</div>}
          {offer.tag && <div>Tag: {offer.tag}</div>}
          <div>Operation: {offer.operation}</div>
        </div>
        {showDetails && (
          <div className="offer-item-detail">
            {offer.message && <div>Message: {offer.message}</div>}
          </div>
        )}
        <div className="actions">
          <button className="button" onClick={() => onConfirm(offer)}>
            Confirm
          </button>
          <button className="button" onClick={() => onReject(offer)}>
            Reject
          </button>
          {/* <button onClick={handleToggleDetails}> */}
          {/*   {showDetails ? "Hide Details" : "Show Details"} */}
          {/* </button> */}
        </div>
      </div>
    </div>
  );
};

export default OfferItem;
