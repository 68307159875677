import axios from "axios";

// Создаем экземпляр axios с базовым URL и настройками
const apiUrl = process.env.REACT_APP_API_URL;
const apiRust = axios.create({
  baseURL: apiUrl + "/api",
  // baseURL: apiUrl,
  withCredentials: true,
});

export { apiRust };
