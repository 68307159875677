import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import { apiRust } from "../api";

function Profile() {
  const { user, getUser } = useAuth();
  const [verificationToken, setVerificationToken] = useState("");
  const [verificationTokenSended, setVerificationTokenSeded] = useState(false);
  const [isRequestDisabled, setIsRequestDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleVerificationRequest = async () => {
    try {
      if (!isRequestDisabled) {
        // Отправляем запрос на эндпоинт для запроса верификации
        await apiRust.post("/auth/request-verify-token", {
          email: user.email,
        });

        setVerificationTokenSeded(true);
        setIsRequestDisabled(true);
        setCountdown(60); // 60 секунд
        // Показываем уведомление об успешной отправке запроса
        toast.success("Verification token sent to your email!");
      } else {
        toast.info("Please wait 60 seconds before requesting another token.");
      }
    } catch (error) {
      // Показываем уведомление об ошибке
      toast.error("Error sending verification token.");
    }
  };

  const handleVerifyToken = async () => {
    try {
      // Отправляем запрос на эндпоинт для подтверждения токена
      await apiRust.post("/auth/verify", {
        email: user.email,
        token: verificationToken,
      });

      // Обновляем информацию о пользователе, если есть изменения
      getUser();

      // Показываем уведомление об успешной верификации
      toast.success("Account verified successfully!");
    } catch (error) {
      // Показываем уведомление об ошибке
      toast.error("Error verifying account.");
    }
  };

  useEffect(() => {
    // Обновляем таймер каждую секунду, если он активен
    const interval = setInterval(() => {
      if (isRequestDisabled && countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1);
      } else {
        setIsRequestDisabled(false);
        setCountdown(0);
      }
    }, 1000);

    // Очистка таймера при размонтировании компонента
    return () => clearInterval(interval);
  }, [isRequestDisabled, countdown]);

  return (
    <div className="centered-form">
      <div className="profile-container">
        <h2>User Profile</h2>
        <p>Email: {user.email}</p>
        <p>Verified: {user.is_verified ? "Yes" : "No"}</p>
        {!user.is_verified && (
          <div>
            <button
              className="button"
              onClick={handleVerificationRequest}
              disabled={isRequestDisabled}
            >
              Request Verification Token
            </button>
            {verificationTokenSended && (
              <div>
                {isRequestDisabled && (
                  <p>
                    Wait {countdown} seconds before requesting another token.
                  </p>
                )}
                <label htmlFor="verificationToken"></label>
                <input
                  type="text"
                  className="input"
                  id="verificationToken"
                  value={verificationToken}
                  placeholder="Verification Token:"
                  onChange={(e) => setVerificationToken(e.target.value)}
                />
                <button className="button" onClick={handleVerifyToken}>
                  Verify Account
                </button>
              </div>
            )}
          </div>
        )}
        <p>Is admin: {user.is_superuser ? "Yes" : "No"}</p>
      </div>
    </div>
  );
}

export default Profile;
