import Select from "react-select";
import { useState } from "react";
import { apiRust } from "../../api";
import { toast } from "react-toastify";

import { useApp } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

const TagListEditor = () => {
  const { isAdmin } = useAuth();
  const { tagList, getTags } = useApp();
  const [tagValue, setTagValue] = useState();
  const [newValue, setNewValue] = useState("");

  const handleChange = async () => {
    if (isAdmin && tagValue) {
      try {
        await apiRust.put(`/tags/${tagValue.value}`, {
          tag: newValue,
          cid: tagValue.value,
        });

        toast.success(`Tag ${newValue} changed`);
        setTagValue(null);
        setNewValue("");
        await getTags();
      } catch (error) {
        toast.error(`Error when changing tag`);
        console.error("Error when changing tag", error);
      }
    }
  };

  const handleRemove = async () => {
    if (isAdmin && tagValue) {
      try {
        await apiRust.delete(`/tags/${tagValue.value}`);

        toast.success(`Tag ${tagValue.label} removed`);
        await getTags();
        setTagValue(null);
        setNewValue("");
      } catch (error) {
        toast.error(`Error when removing tag`);
        console.error("Error when removing tag", error);
      }
    }
  };

  return (
    <div className="editor-container">
      <h3>Tags: </h3>
      <div className="multiselect">
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "#D9D9D9",
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              color: "#262626",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: "#262626",
            }),
          }}
          isClearable
          onChange={(newValue) => {
            setTagValue(newValue);
            setNewValue(newValue ? newValue.label : "");
          }}
          options={tagList}
          value={tagValue}
        />

        <div className="actions">
          <input
            className="input"
            value={newValue}
            type="text"
            onChange={(e) => setNewValue(e.target.value)}
          />
          <div>
            <button className="button" onClick={handleChange}>
              Apply Changes
            </button>
            <button className="button" onClick={handleRemove}>
              RemoveTag
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagListEditor;
