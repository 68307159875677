import Select from "react-select";
import { useState } from "react";
import { apiRust } from "../../api";
import { toast } from "react-toastify";

import { useAuth } from "../../contexts/AuthContext";
import { useApp } from "../../contexts/AppContext";

const ArtistListEditor = () => {
  const { isAdmin } = useAuth();
  const { artistList, getArtists } = useApp();
  const [artistValue, setartistValue] = useState();
  const [newValue, setNewValue] = useState("");

  const handleChange = async () => {
    if (isAdmin && artistValue) {
      try {
        await apiRust.put(`/artists/${artistValue.value}`, {
          name: newValue,
          cid: artistValue.value,
        });

        toast.success(`artist ${newValue} changed`);
        setartistValue(null);
        setNewValue("");
        await getArtists();
      } catch (error) {
        toast.error(`Error when changing artist`);
        console.error("Error when changing artist", error);
      }
    }
  };

  const handleRemove = async () => {
    if (isAdmin && artistValue) {
      try {
        await apiRust.delete(`/artists/${artistValue.value}`);

        toast.success(`artist ${artistValue.label} removed`);
        await getArtists();
        setartistValue(null);
        setNewValue("");
      } catch (error) {
        toast.error(`Error when removing artist`);
        console.error("Error when removing artist", error);
      }
    }
  };

  return (
    <div className="editor-container">
      <h3>Artists: </h3>
      <div className="multiselect">
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "#D9D9D9",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: "#262626",
            }),
          }}
          isClearable
          onChange={(newValue) => {
            setartistValue(newValue);
            setNewValue(newValue ? newValue.label : "");
          }}
          options={artistList}
          value={artistValue}
        />
        <div className="actions">
          <input
            className="input"
            value={newValue}
            type="text"
            onChange={(e) => setNewValue(e.target.value)}
          />
          <div>
            <button className="button" onClick={handleChange}>
              Apply Changes
            </button>
            <button className="button" onClick={handleRemove}>
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistListEditor;
