import React from "react";
import st from "./DeleteButton.module.css"; // Замените путь на фактический путь к вашему файлу стилей
import DeleteButtonIco from "../../assets/delete.svg";

const DeleteButton = () => {
  return (
    <div className={st.DeleteButton}>
      <img src={DeleteButtonIco} alt="Pause" className={st.playIcon} />
    </div>
  );
};

export default DeleteButton;
