import { createContext, useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { apiRust } from "../api";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [tagList, setTagList] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [searchMode, setSearchMode] = useState(false);

  const getArtists = async () => {
    await apiRust
      .get("/artists")
      .then((response) => {
        const options = response.data.map((artist) => ({
          value: artist.cid,
          label: artist.name,
        }));
        setArtistList(options);
      })
      .catch((error) => {
        toast.error("Error getting list of artists");
        console.error("Error getting list of artists", error);
      });
  };
  const getTags = async () => {
    await apiRust
      .get("/tags")
      .then((response) => {
        const options = response.data.map((tag) => ({
          value: tag.cid,
          label: tag.tag,
        }));
        setTagList(options);
      })
      .catch((error) => {
        toast.error("Error getting list of tags");
        console.error("Error getting list of tags", error);
      });
  };

  const getAlbums = async () => {
    await apiRust
      .get("/albums")
      .then((response) => {
        setAlbums(response.data);
      })
      .catch((error) => {
        toast.error("Error receiving albums");
        console.error("Error receiving albums", error);
      });
  };
  useEffect(() => {
    getArtists();
    getTags();
    getAlbums();
  }, []);
  useEffect(() => {
    if (albums.length === 0) {
      getAlbums();
      // setCurrentPage(0);
    }
  }, [albums]);

  return (
    <AppContext.Provider
      value={{
        albums,
        setAlbums,
        tagList,
        setTagList,
        getTags,
        artistList,
        setArtistList,
        getArtists,
        searchMode,
        setSearchMode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within an AuthProvider");
  }
  return context;
};

export { AppProvider, useApp };
