import React from "react";
import st from "./PlayButton.module.css"; // Замените путь на фактический путь к вашему файлу стилей
import PlayIcon from "./play.svg";
import PauseButton from "./pause.svg";

const PlayButton = ({ played }) => {
  return (
    <>
      {played ? (
        <div className={st.playButton}>
          <img src={PauseButton} alt="Pause" className={st.playIcon} />
        </div>
      ) : (
        <div className={st.playButton}>
          <img src={PlayIcon} alt="Play" className={st.playIcon} />
        </div>
      )}
    </>
  );
};

export default PlayButton;
