import React, { useEffect, useState } from "react";
import { usePlayer } from "../../contexts/PlayerContext";
import SongEdit from "./SongEdit";
import SongPreview from "./SongPreview";

const SongControler = ({
  song,
  updateCallBack,
  index,
  setPlayListAndPlaySongWithCid,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { playedSong, paused, setPaused, addToPlaylist } = usePlayer();
  const [played, setPlayed] = useState(false);
  const [selected, setSelected] = useState(false);
  const handleSetSong = () => {
    if (playedSong != song) {
      setPlayListAndPlaySongWithCid(index);
      if (paused) {
        setPaused(!paused);
      }
    } else {
      setPaused(!paused);
    }
  };
  const handleAddToPlaylist = () => {
    addToPlaylist(song);
  };
  useEffect(() => {
    if (playedSong !== undefined) {
      if (playedSong.cid === song.cid) {
        setSelected(true);
        if (!paused) {
          setPlayed(true);
        } else {
          setPlayed(false);
        }
      } else {
        setSelected(false);
        setPlayed(false);
      }
    }
  }, [paused, playedSong]);

  return (
    <>
      {!editMode ? (
        <SongPreview
          song={song}
          index={index}
          handleSetSong={handleSetSong}
          addToPlayList={handleAddToPlaylist}
          played={played}
          editMode={editMode}
          setEditMode={setEditMode}
          selected={selected}
        // setPlayed={setPlayed}
        />
      ) : (
        <SongEdit
          song={song}
          index={index}
          updateCallBack={updateCallBack}
          handleSetSong={handleSetSong}
          played={played}
          editMode={editMode}
          setEditMode={setEditMode}
          addToPlayList={handleAddToPlaylist}
          selected={selected}
        />
      )}
    </>
  );
};
export default SongControler;
