import { useState } from "react";
import { NavLink } from "react-router-dom";
import SearchBar from "../search/SearchBar";
import st from "./Header.module.css";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

const setActive = ({ isActive }) =>
  isActive ? `${st.navItem} ${st.active}` : st.navItem;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const links = [
    <NavLink className={setActive} to="/about">
      About
    </NavLink>,
    <NavLink className={setActive} to="/">
      Home
    </NavLink>,
  ];

  return (
    <div className={st.appHeader}>
      <div className={st.navList}>{links} </div>
      <div className={st.searchBarWraper}>
        <SearchBar />
      </div>
      <div className={st.navRight}>
        <BurgerMenu
          isOpen={menuOpen}
          onClose={closeMenu}
          setMenuOpen={setMenuOpen}
          links={links}
        />
      </div>
    </div>
  );
};
export default Header;
