import { useState } from "react";
import { CircularSliderWithChildren } from "react-circular-slider-svg";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import LoopButton from "../../ui/LoopButton/LoopButton";
import Modal from "../../ui/Modal/Modal";
import PlayButton from "../../ui/PlayButton/PlayButton";
import st from "./MiniPlayer.module.css";

const MiniPlayer = ({
  progress,
  setTime,
  Queue,
  openCloseQueue,
  playNextSong,
  playPreviousSong,
  Next,
  Prev,
  paused,
  setPaused,
  loop,
  setLoop,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [controledPosition, setControledPostion] = useState({
    x: -5,
    y: 0,
  });
  const onControlledDrag = (e, position) => {
    const { y } = position;
    const x = controledPosition.x;
    // console.log(x, y);
    setControledPostion({ x, y });
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <>
      {isMenuOpen ? (
        <Modal visible={isMenuOpen} setVisible={setMenuOpen}>
          <div
            className={st.circleContainer}
            style={{ top: `${controledPosition.y + 400}px` }}
          >
            <div className={st.bg} />
            <div className={st.circle}>
              <CircularSliderWithChildren
                size={250}
                startAngle={0}
                endAngle={180}
                // arcBackgroundColor={"var(--black-main)"}
                handle1={{
                  value: progress,
                  onChange: (v) => setTime(v),
                }}
                arcColor="var(--green)"
              >
                <div className={st.sercleControlSection}>
                  <img
                    className={st.prev}
                    src={Prev}
                    alt="prev"
                    onClick={playPreviousSong}
                  />
                  <div
                    className={st.playPauseButton}
                    onClick={() => {
                      setPaused(!paused);
                    }}
                  >
                    <PlayButton played={!paused} />
                  </div>
                  <img
                    src={Next}
                    alt="next"
                    className={st.next}
                    onClick={playNextSong}
                  />
                  <div className={st.playListButton} onClick={openCloseQueue}>
                    <img src={Queue} alt="queue" />
                    <Link to="/queue" />
                  </div>
                  <div className={st.additionalControls}>
                    <LoopButton loop={loop} setLoop={setLoop} />
                  </div>
                </div>
              </CircularSliderWithChildren>
            </div>
          </div>
        </Modal>
      ) : (
        <Draggable
          position={controledPosition}
          // cancel=".expandSvg"
          onDrag={onControlledDrag}
          axis="y"
        >
          <div
            className={st.expand}
          // onTouchStart={toggleMenu}
          // onTouchMove={handleMove}
          >
            <svg
              onClick={toggleMenu}
              onTouchEnd={toggleMenu}
              // onMouseDown={(e) => {
              //   e.stopPropagation();
              // }}
              className="expandSvg"
              viewBox="0 0 24 24"
              fill="none"
              height="100%"
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 3C7.03005 3 3 7.02908 3 12C3 16.9699 7.03005 21 12 21C16.9699 21 21 16.9699 21 12C21 7.02908 16.9699 3 12 3Z"
                  stroke="var(--ci-primary-color)"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>{" "}
                <path
                  d="M13 9L10 12L13 15"
                  stroke="var(--ci-primary-color)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </Draggable>
      )}
    </>
  );
};
export default MiniPlayer;
