import React, { useState } from "react";
import { apiRust } from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetResult, setResetResult] = useState(null);
  const [isMailSent, setIsMailSent] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const isPasswordValid = () => {
    return newPassword.length >= 6;
  };

  const doPasswordsMatch = () => {
    return newPassword === confirmNewPassword;
  };

  const handleSendMail = async () => {
    try {
      await apiRust.post("/auth/forgot-password", {
        email: email,
      });
      toast.success("Password reset email sent. Please check your inbox.");
      setIsMailSent(true);
      // setResetResult();
    } catch (error) {
      setIsMailSent(false);
      const errorMsg =
        error.response.data.detail[0].msg ||
        error.response.data.detail ||
        "An unknown error has occurred";
      toast.error(`Error sending reset email: ${errorMsg}`);
    }
  };

  const handlePasswordReset = async () => {
    try {
      if (!isPasswordValid()) {
        throw new Error(
          "The password is too short. Minimum length: 6 characters.",
        );
      }

      if (!doPasswordsMatch()) {
        throw new Error("Password mismatch.");
      }

      const response = await apiRust.post("/auth/reset-password", {
        email: email,
        token: token,
        password: newPassword,
      });

      if (response.status === 200 && response.data === null) {
        // Успешный сброс пароля
        toast.success("Successful password reset");
        navigate("/login");
      } else {
        // Обработка ошибок
        const validationErrors = response.data && response.data.detail;
        if (validationErrors) {
          const errorMessage = Array.isArray(validationErrors)
            ? validationErrors.map((errorItem) => errorItem.msg).join(", ")
            : validationErrors.toString();
          setResetResult(`Error during password reset: ${errorMessage}`);
        } else {
          setResetResult("Error during password reset. Please try again.");
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        const validationErrors = error.response.data.detail;
        const errorMessage = Array.isArray(validationErrors)
          ? validationErrors.map((errorItem) => errorItem.msg).join(", ")
          : validationErrors.toString();
        setResetResult(`Error during password reset: ${errorMessage}`);
      } else {
        setResetResult(`Error during password reset: ${error.message}`);
      }
    }
  };

  return (
    <div className="centered-form">
      <div className="form-container">
        <h2>Password Reset</h2>
        <form>
          {!isMailSent ? (
            <div>
              <div className="form-group">
                <label htmlFor="email"></label>
                <input
                  id="email"
                  placeholder="Email"
                  className="input"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="form-group">
                <button
                  className="button"
                  type="button"
                  onClick={handleSendMail}
                >
                  Send Mail
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="token"></label>
                <input
                  id="token"
                  placeholder="Token"
                  className="input"
                  type="text"
                  value={token}
                  onChange={handleTokenChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newPassword"></label>
                <input
                  id="newPassword"
                  type="password"
                  placeholder="New Password"
                  className="input"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="confirmNewPassword"></label>
                <input
                  id="confirmNewPassword"
                  placeholder="Confirm New Password"
                  type="password"
                  className="input"
                  value={confirmNewPassword}
                  onChange={handleConfirmNewPasswordChange}
                />
              </div>

              <div className="form-group">
                <button
                  className="button"
                  type="button"
                  onClick={handlePasswordReset}
                >
                  Reset Password
                </button>
              </div>
            </>
          )}

          {resetResult && (
            <div>
              <h3>{resetResult}</h3>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
