import React from "react";

function About() {
  return (
    <div>
      <div class="about-container">
        <h1>Arknights Music Search</h1>
        <div lang="en">
          <h2>En</h2>
          <p>
            Arknights Music Search is a convenient platform for finding official
            names of music tracks released in the world of Arknights. Given that
            a significant portion of the music is in the Chinese language,
            searching for a specific composition from the game can be
            challenging. Our platform allows you to search for songs based on
            associations (tags) and artists.
          </p>

          <p>
            For example, if you found a track on YouTube with the title "dsdevr"
            – this is the file name of the track in the game, but it does not
            reveal the official track name. Here you can discover the actual
            track title. You can also search for tracks associated with events
            or specific bosses.
          </p>

          <p>
            If you are interested in all tracks by a specific artist, simply
            enter their name, and you will get a list of albums and songs
            released in the Arknights world in which they participated.
          </p>

          <p>
            Unfortunately, not all tracks from the game are released on Monster
            Siren Records they can only be found as cut files from the game.
            Perhaps, in the future, they will also be added to our platform.
          </p>
          <p>
            The purpose of this project is to facilitate the search for music,
            if official representatives of msr see this and you have something
            to do with me, please contact me at arknights.music@gmail.com, I am
            ready to discuss any topics.
          </p>
          <p>All rights reserved by Hypergryph. Links to official resources:</p>
          <div className="of-link">
            <a href="https://monster-siren.hypergryph.com">
              Monster Siren Records
            </a>
          </div>
          <div className="of-link">
            <a href="https://www.youtube.com/channel/UCzow4LPT9IunlkFdt4Y0-FQ">
              YouTube topic MSR
            </a>
          </div>
        </div>
        {/* <div lang="ru"> */}
        {/*   <h2>Ru</h2> */}
        {/*   <p> */}
        {/*     Arknights Music Search представляет собой удобную платформу для */}
        {/*     поиска официальных названий музыкальных композиций, выпущенных в */}
        {/*     мире Arknights. С учетом того, что значительная часть музыки */}
        {/*     представлена на китайском языке, поиск конкретной композиции из игры */}
        {/*     может стать вызовом. Наш ресурс предоставляет возможность */}
        {/*     осуществлять поиск песен по ассоциациям (тегам) и артистам. */}
        {/*   </p> */}
        {/**/}
        {/*   <p> */}
        {/*     Допустим, вы нашли трек на YouTube с названием "dsdevr" – это имя */}
        {/*     файла трека в игре, но оно не раскрывает официальное название трека. */}
        {/*     Здесь вы сможете узнать настоящее название трека. Вы также можете */}
        {/*     искать треки, связанные с событиями или конкретными боссами. */}
        {/*   </p> */}
        {/**/}
        {/*   <p> */}
        {/*     Если вам интересны все треки определенного артиста, просто введите */}
        {/*     его имя, и вы получите список альбомов и песен, в создании которых */}
        {/*     он принимал участие, выпущенных в рамках мира Arknights. */}
        {/*   </p> */}
        {/**/}
        {/*   <p> */}
        {/*     К сожалению, не все треки из игры выпущены на Monster Siren Records, */}
        {/*     их можно обнаружить только в виде вырезанных файлов из игры. */}
        {/*     Возможно, в будущем они также будут добавлены на наш ресурс. */}
        {/*   </p> */}
        {/* </div> */}
      </div>
    </div>
  );
}
export default About;
