import ListItem from "../ListItems/ListItems";
import st from "./SongPreview.module.css";
import PlayButton from "../../ui/PlayButton/PlayButton";
import MenuButton from "../../ui/MenuButton/MenuButton";
import { Menu, Item, useContextMenu } from "react-contexify";
import { useAuth } from "../../contexts/AuthContext";
import SelectedSongIco from "../../ui/SelectedSongIco/SelectedSongIco";

const SongPreview = ({
  song,
  selected,
  index,
  setEditMode,
  handleSetSong,
  played,
  addToPlayList,
}) => {
  const SongPreviewMenu = `SongPreviewMenuToSong${song.cid}`;
  const { show } = useContextMenu({
    id: SongPreviewMenu,
  });
  function displayMenu(e) {
    show({
      event: e,
    });
  }
  const { isAuthorized } = useAuth();

  return (
    <div
      className={`${st.songContainer} ${selected ? st.selected : ""}`}
      onContextMenu={displayMenu}
    >
      <div className={st.playButton} onClick={handleSetSong}>
        <PlayButton played={played} />
      </div>
      {played ? (
        <div className={st.selectedSongIco}>
          <SelectedSongIco />
        </div>
      ) : (
        <div className={st.songNumber}>{index + 1}</div>
      )}

      <strong className={st.title}>{song.name}</strong>
      <div className={st.artists}>
        <ListItem items={song.artists} />
      </div>
      <div className={st.tags}>
        <ListItem items={song.tags} />
      </div>
      <div className={st.menu} onClick={displayMenu}>
        <MenuButton />
      </div>
      <Menu id={SongPreviewMenu} theme="dark">
        {isAuthorized && (
          <Item
            onClick={() => {
              setEditMode(true);
            }}
          >
            Edit
          </Item>
        )}
        <Item
          onClick={() => {
            addToPlayList();
          }}
        >
          Add to queue
        </Item>
      </Menu>
    </div>
  );
};

export default SongPreview;
