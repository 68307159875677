import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home/Home";
import Layout from "./components/layout/Layout";
import LoginComponent from "./components/auth/Login";
import RegistrationComponent from "./components/auth/Registration";
import ForgotPasswordComponent from "./components/auth/ForgotPassword";
import "react-contexify/dist/ReactContexify.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Profile from "./pages/Profile";
import AdminOfferPage from "./pages/AdminOffers";
import AdminTagsArtists from "./pages/AdmingTagsArtists";
import { AlbumPage } from "./pages/Album";
import { AuthProvider } from "./contexts/AuthContext";
import { AppProvider } from "./contexts/AppContext";
import { PlayerProvider } from "./contexts/PlayerContext";
import QueuePage from "./pages/Queue/Queue";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<LoginComponent />} />
      <Route path="/register" element={<RegistrationComponent />} />
      <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/offers" element={<AdminOfferPage />} />
      <Route path="/tags-artists" element={<AdminTagsArtists />} />
      <Route path="/album/:cid" element={<AlbumPage />} />
      <Route path="/queue" element={<QueuePage />} />
    </Route>,
  ),
);
function App() {
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className="total-container" id="total-container">
        <AuthProvider>
          <AppProvider>
            <PlayerProvider>
              <RouterProvider router={router} />
            </PlayerProvider>
          </AppProvider>
        </AuthProvider>
      </div>
    </>
  );
}

export default App;
