import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AlbumPriview from "../../components/album/AlbumPreview";
import { useApp } from "../../contexts/AppContext";
import st from "./Home.module.css";

function Home() {
  const { albums, setAlbums } = useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const albumsPerPage = searchParams.get("offset") || 27;

  useEffect(() => {
    const page = parseInt(searchParams.get("page"));
    setCurrentPage(isNaN(page) ? 0 : page - 1);
  }, [searchParams]);

  const albumUpdateCallBack = async (album) => {
    try {
      const updatedAlbum = albums.map((obj) =>
        obj.cid === album.cid ? { ...obj, ...album } : obj,
      );
      setAlbums(updatedAlbum);
    } catch (error) {
      toast.error("Error updating album");
      console.error("Error updating album", error);
    }
  };
  const handlePageClick = ({ selected }) => {
    setSearchParams({ page: selected + 1 });
  };

  if (!albums) {
    return <p>Loading...</p>;
  }
  const offset =
    currentPage * albumsPerPage > 0 ? currentPage * albumsPerPage : 0;

  const currentAlbums = albums.slice(offset, offset + albumsPerPage);
  return (
    <div className={st.homePage}>
      <div className={st.albumList}>
        {currentAlbums.map((album) => (
          <AlbumPriview
            key={album.cid}
            album={album}
            albumUpdateCallBack={albumUpdateCallBack}
          />
        ))}
      </div>
      {albums.length > albumsPerPage && (
        <div className={st.paginationContainer}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            forcePage={currentPage}
            pageCount={Math.ceil(albums.length / albumsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
}
export default Home;
