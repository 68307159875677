import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { apiRust } from "../../api";
import { useApp } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

const createArtsitOption = (artist) => ({
  label: artist.name,
  value: artist.cid,
});
const restore = (artist) => ({
  name: artist.label,
  cid: artist.value,
});
const Artist = ({ artists, setParentArtistsValue }) => {
  const { isAdmin } = useAuth();
  const { artistList, setArtistList } = useApp();
  const artistsOpts = artists.map((artist) => createArtsitOption(artist));
  const [isLoading, setIsLoading] = useState(false);
  // const [artistList, setArtistList] = useState(artistsOpts);
  const [artistsValue, setArtistsValue] = useState(artistsOpts);
  useEffect(() => {
    setParentArtistsValue(artistsValue.map((value) => restore(value)));
  }, []);

  const handleAddNewArtist = async (inputValue) => {
    if (isAdmin) {
      try {
        setIsLoading(true);
        const response = await apiRust.post("/artists/", {
          name: inputValue,
        });
        setIsLoading(false);
        const newArtistOption = createArtsitOption(response.data);
        setArtistList((prevOptions) => [...prevOptions, newArtistOption]);
        setArtistsValue((prev) => [...prev, newArtistOption]);
        setParentArtistsValue((prev) => [...prev, response.data]);
        toast.success(`Artist  ${inputValue} added to database`);
      } catch (error) {
        toast.error(`Error when adding`);
        console.error("Error when adding new Artist", error);
      }
    } else {
      const artist = {
        name: inputValue,
        cid: null,
      };
      const newArtistOption = createArtsitOption(artist);
      setArtistList((prevOptions) => [...prevOptions, newArtistOption]);
      setArtistsValue((prev) => [...prev, newArtistOption]);
      setParentArtistsValue((prev) => [...prev, artist]);
    }
  };

  return (
    <div>
      <strong>Artists: </strong>
      <div className="multiselect">
        <CreatableSelect
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "inherit",
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: "#D9D9D9",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: "#262626",
            }),
          }}
          isClearable
          isMulti
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={(newValue) => {
            setArtistsValue(newValue);
            setParentArtistsValue(newValue.map((value) => restore(value)));
          }}
          onCreateOption={handleAddNewArtist}
          options={artistList}
          value={artistsValue}
        />
      </div>
    </div>
  );
};

export default Artist;
