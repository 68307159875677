import React from "react";
import { slide as Menu } from "react-burger-menu";
import { useNavigate } from "react-router-dom";
import "./BurgrerMenu.css";
import { NavLink, Link } from "react-router-dom";
import { apiRust } from "../../api";
import { useAuth } from "../../contexts/AuthContext";
import Burger from "./burger.svg";

const BurgerMenu = ({ isOpen, onClose, links, setMenuOpen }) => {
  const handleMenuClose = () => {
    if (isOpen) {
      onClose(!isOpen);
    }
  };
  const { isAuthorized, setIsAuthorized, user } = useAuth();

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await apiRust.get("auth/logout");
      setIsAuthorized(false);
      navigate("/");
      handleMenuClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isAuthorized ? (
        <>
          <button
            className="bm-burger-custom-button"
            onClick={() => setMenuOpen(!isOpen)}
          >
            {user.email}
          </button>
        </>
      ) : (
        <NavLink className="nav-login" to="/login" onClick={handleMenuClose}>
          Login
        </NavLink>
      )}
      <img
        src={Burger}
        alt="menu"
        className="bm-burger-custom-button-mini"
        onClick={() => setMenuOpen(!isOpen)}
      ></img>
      <Menu right isOpen={isOpen} onClose={handleMenuClose}>
        {isAuthorized ? (
          <>
            <NavLink
              className="bm-item"
              to="/profile"
              onClick={handleMenuClose}
            >
              Profile
            </NavLink>
            {user.is_superuser && (
              <>
                <NavLink
                  className="bm-item"
                  to="/offers"
                  onClick={handleMenuClose}
                >
                  Offers
                </NavLink>
                <NavLink
                  className="bm-item"
                  to="/tags-artists"
                  onClick={handleMenuClose}
                >
                  Tags Atrists
                </NavLink>
              </>
            )}
            <a href="##" className="bm-item" onClick={handleLogout}>
              Logout
            </a>
          </>
        ) : (
          <>
            <Link className="bm-item" to="/login" onClick={handleMenuClose}>
              Login
            </Link>
          </>
        )}
        {links &&
          links.map((link, index) => (
            <React.Fragment key={index}>
              {React.cloneElement(link, {
                className: "bm-item header-link",
              })}
            </React.Fragment>
          ))}
      </Menu>
    </>
  );
};

export default BurgerMenu;
