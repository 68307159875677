import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiRust } from "../../api";
import { useAuth } from "../../contexts/AuthContext";

// const LoginComponent = ({ isAuthorized, setIsAuthorized }) => {
const LoginComponent = () => {
  const { setIsAuthorized } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginResult, setLoginResult] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      await apiRust.post(
        "auth/login",
        `email=${email}&password=${password}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      // if (response.status === 204) {
      setLoginResult("Ok!");
      setIsAuthorized(true);
      navigate(-1);
    } catch (error) {
      console.log(error);
      setLoginResult("Login bad credentials");
    }
  };

  return (
    <div className="centered-form">
      <div className="form-container">
        <h2>Login</h2>
        <form>
          <div className="form-group">
            <label htmlFor="email"></label>
            <input
              id="email"
              className="input"
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password"></label>
            <input
              id="password"
              placeholder="Password"
              className="input"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group">
            <button className="button" type="button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </form>
        <div className="other-auth-options">
          <Link className="auth-option" to="/register">
            Register
          </Link>
          <Link className="auth-option" to="/forgot-password">
            Forgot password?
          </Link>
        </div>
        {loginResult && (
          <div>
            <h3>Login Result:</h3>
            <p>{loginResult}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginComponent;
