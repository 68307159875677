const SelectedSongIco = () => {
  return (
    <svg
      fill="var(--green)"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 468.393 468.394"
      transform="rotate(270)"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <path d="M333.055,412.426v42.391c0,7.498,5.29,13.577,11.777,13.577h0.121c6.487,0,11.777-6.079,11.777-13.577v-42.391 c0-7.489-5.29-13.577-11.898-13.577C338.329,398.849,333.055,404.937,333.055,412.426z"></path>{" "}
          <path d="M446.417,387.925h0.116c6.5,0,11.773-6.079,11.773-13.577v-42.387c0-7.49-5.273-13.577-11.89-13.577 c-6.492,0-11.774,6.087-11.774,13.577v42.387C434.643,381.846,439.925,387.925,446.417,387.925z"></path>{" "}
          <path d="M395.475,307.443c6.488,0,11.777-6.079,11.777-13.577v-42.395c0-7.481-5.289-13.569-11.902-13.569 c-6.491,0-11.773,6.079-11.773,13.569v42.395c0,7.482,5.282,13.577,11.773,13.577H395.475z"></path>{" "}
          <path d="M10.086,374.348c0,5.33,3.519,9.646,7.851,9.646h393.476c4.332,0,7.851-4.315,7.851-9.646v-42.402 c0-5.326-3.519-9.65-7.851-9.65H17.937c-4.332,0-7.851,4.324-7.851,9.65V374.348z"></path>{" "}
          <path d="M10.086,293.866c0,5.338,3.519,9.654,7.851,9.654h343.499c4.34,0,7.851-4.316,7.851-9.654v-42.395 c0-5.334-3.511-9.646-7.851-9.646H17.937c-4.332,0-7.851,4.312-7.851,9.646V293.866z"></path>{" "}
          <path d="M10.086,454.829c0,5.329,3.519,9.646,7.851,9.646h292.978c4.332,0,7.851-4.316,7.851-9.646v-42.403 c0-5.325-3.519-9.649-7.851-9.649H17.937c-4.332,0-7.851,4.324-7.851,9.649V454.829z"></path>{" "}
          <path d="M10.086,216.92c0,5.332,3.519,9.647,7.851,9.647h311.315c4.332,0,7.851-4.316,7.851-9.647v-42.399 c0-5.332-3.519-9.648-7.851-9.648H17.937c-4.332,0-7.851,4.316-7.851,9.648V216.92z"></path>{" "}
          <path d="M363.284,230.493c6.496,0,11.777-6.083,11.777-13.573v-42.391c0-7.49-5.281-13.575-11.89-13.575 c-6.505,0-11.778,6.085-11.778,13.575v42.391c0,7.49,5.273,13.573,11.778,13.573H363.284z"></path>{" "}
          <path d="M191.753,94.038v42.4c0,7.49,5.282,13.573,11.773,13.573h0.121c6.49,0,11.776-6.083,11.776-13.573v-42.4 c0-7.482-5.286-13.573-11.896-13.573C197.027,80.473,191.753,86.556,191.753,94.038z"></path>{" "}
          <path d="M320.928,69.537c6.501,0,11.774-6.083,11.774-13.573V13.573c0-7.49-5.273-13.573-11.89-13.573 c-6.492,0-11.774,6.083-11.774,13.573v42.391c0,7.49,5.282,13.573,11.774,13.573H320.928z"></path>{" "}
          <path d="M293.659,55.963V13.573c0-5.332-3.52-9.648-7.852-9.648H17.937c-4.332,0-7.851,4.316-7.851,9.648v42.399 c0,5.332,3.519,9.647,7.851,9.647h267.87C290.139,65.619,293.659,61.295,293.659,55.963z"></path>{" "}
          <path d="M10.086,136.444c0,5.332,3.519,9.65,7.851,9.65h151.671c4.332,0,7.851-4.318,7.851-9.65V94.046 c0-5.332-3.519-9.647-7.851-9.647H17.937c-4.332,0-7.851,4.315-7.851,9.647V136.444z"></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
export default SelectedSongIco;
