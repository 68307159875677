import React from "react";
import st from "./MenuButton.module.css"; // Замените путь на фактический путь к вашему файлу стилей
import MenuButtonIco from "../../assets/menu.svg";

const MenuButton = () => {
  return (
    <div className={st.MenuButton}>
      <img src={MenuButtonIco} alt="Pause" className={st.playIcon} />
    </div>
  );
};

export default MenuButton;
