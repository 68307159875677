import ListItem from "../ListItems/ListItems";
import st from "./SongOnPlayList.module.css";
import PlayButton from "../../ui/PlayButton/PlayButton";
import { usePlayer } from "../../contexts/PlayerContext";
import { Menu, Item, useContextMenu } from "react-contexify";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../ui/MenuButton/MenuButton";
import { useEffect, useState } from "react";

const SongOnPlayList = ({ song, index }) => {
  const { paused, setPaused, removeFromPlaylist, playedSong, playSongByCid } =
    usePlayer();
  const [played, setPlayed] = useState(false);
  const navigate = useNavigate();
  const playListMenuID = `playListMenu${song.cid}`;
  const { show } = useContextMenu({
    id: playListMenuID,
  });
  function displayMenu(e) {
    show({
      event: e,
    });
  }

  useEffect(() => {
    if (playedSong !== undefined) {
      if (playedSong.cid === song.cid && !paused) {
        setPlayed(true);
      } else {
        setPlayed(false);
      }
    }
  }, [paused, playedSong]);

  const handleSetSong = () => {
    if (playedSong !== undefined) {
      if (playedSong.cid !== song.cid) {
        playSongByCid(song.cid);
        if (paused) {
          setPaused(!paused);
        }
      } else {
        setPaused(!paused);
      }
    } else {
      playSongByCid(song.cid);
      if (paused) {
        setPaused(!paused);
      }
    }
  };

  const removeFromQueue = () => {
    removeFromPlaylist(song.sid);
  };

  return (
    <div className={st.songContainer} onContextMenu={displayMenu}>
      <div className={st.playButton} onClick={handleSetSong}>
        <PlayButton played={played} />
      </div>
      <div className={st.songNumber}>{index + 1}</div>
      <strong className={st.title}>{song.name}</strong>
      <div className={st.artists}>
        <ListItem items={song.artists} />
      </div>
      <div className={st.tags}>
        <ListItem items={song.tags} />
      </div>
      <div className={st.menu} onClick={displayMenu}>
        <MenuButton />
      </div>
      <Menu id={playListMenuID} theme="dark">
        <Item
          onClick={() => {
            navigate(`/album/${song.album_cid}`);
          }}
        >
          To the album
        </Item>
        <Item onClick={removeFromQueue}>Remove from queue</Item>
      </Menu>
    </div>
  );
};

export default SongOnPlayList;
