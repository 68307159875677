import React, { useEffect, useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { usePlayer } from "../../contexts/PlayerContext";
import PlayButton from "../../ui/PlayButton/PlayButton";
import Next from "../../assets/next.svg";
import Prev from "../../assets/prev.svg";
import "./Player.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Queue from "../../assets/queue.svg";
import { toast } from "react-toastify";
import { apiRust } from "../../api";
import LoopButton from "../../ui/LoopButton/LoopButton";
import MiniPlayer from "./MiniPlayer";

const Player = () => {
  const {
    paused,
    setPaused,
    playedSong,
    setPlayedSong,
    playNextSong,
    playPreviousSong,
  } = usePlayer();
  const [progress, setProgress] = useState(0);
  const [loop, setLoop] = useState(false);
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [validSourceUrl, setValidSourceUrl] = useState(false);

  const openCloseQueue = () => {
    if (location.pathname === "/queue") {
      navigate(-1);
    } else {
      navigate("/queue");
    }
  };

  const getSourceUrl = () => {
    // Разделяем URL по "/"
    const urlParts = playedSong.source_url.split("/");

    // Получаем временную метку из URL
    const timestampHex = urlParts[urlParts.length - 5];

    // Преобразуем временную метку из шестнадцатеричного формата в число
    const timestamp = parseInt(timestampHex, 16);

    // Преобразуем число времени в объект Date
    const dateTime = new Date(timestamp * 1000); // Умножаем на 1000, так как timestamp в секундах

    // Расчет времени ожидания
    const timeToWait = (dateTime - new Date()) / 1000 + 10; // Разницу делим на 1000, так как она в миллисекундах
    // console.log(timeToWait);

    // Если время в прошлом, не ждем
    if (timeToWait <= 0) {
      apiRust
        .get(`/song/${playedSong.cid}/`)
        .then((response) => {
          if (response.data.source_url == playedSong.source_url) {
            toast.error(
              "It is not possible to listen to the song, server error, try again later, if the error does not disappear within 10 minutes, notify the administrator!",
            );
            return response.data.source_url;
          }
          setPlayedSong(response.data);
          setValidSourceUrl(true)
          return response.data.source_url;
        })
        .catch(() => {
          toast.error(
            "It is not possible to listen to the song, server error!",
          );
        });
    } else {
      setValidSourceUrl(true)
      return playedSong.source_url;
    }
  };
  useEffect(() => {
    if (playedSong) {
      setValidSourceUrl(false)
      getSourceUrl();
    }
  }, [playedSong]);
  useEffect(() => {
    const audio = playerRef.current;
    audio.loop = loop;
  }, [loop]);

  const timeListener = () => {
    const audio = playerRef.current;
    const dragPercentage = (audio.currentTime / audio.duration) * 100;
    // console.log("dragPercentage", dragPercentage);
    setProgress(dragPercentage);
  };

  const setTime = (dragPercentage) => {
    const audio = playerRef.current;
    // console.log("dragPercentage", dragPercentage);
    const calculatedValue = parseInt((audio.duration * dragPercentage) / 100);
    // console.log("calculatedValue", calculatedValue);
    audio.currentTime = calculatedValue;
    setProgress(dragPercentage);
  };

  useEffect(() => {
    const audioElement = playerRef.current;
    if (paused) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
  }, [paused]);
  return (
    <>
      {playedSong && playedSong.source_url && (
        <>{validSourceUrl ? (
          < div className="player-container">
            <AudioPlayer
              // autoPlay={true}
              src={playedSong.source_url}
              preload="metadata"
              crossOrigin="anonymous"
              ref={(element) => {
                playerRef.current = element && element.audio.current;
              }}
              onEnded={playNextSong}
              onListen={timeListener}
              listenInterval={20}
              showFilledVolume="true"
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              customControlsSection={[
                <img
                  className="rhap_main-controls-button"
                  src={Prev}
                  alt="prev"
                  onClick={playPreviousSong}
                />,
                <div
                  className="rhap_play-pause-button"
                  onClick={() => {
                    setPaused(!paused);
                  }}
                >
                  <PlayButton played={!paused} />
                </div>,
                <img
                  src={Next}
                  alt="next"
                  className="rhap_main-controls-button"
                  onClick={playNextSong}
                />,
                RHAP_UI.CURRENT_TIME,
                <div className="time-sep">/</div>,
                RHAP_UI.DURATION,
                <div className="rhap_song_name">{playedSong.name}</div>,
                <div
                  className="rhap_main-play-list-button"
                  onClick={openCloseQueue}
                >
                  <img src={Queue} alt="queue" />
                  <Link to="/queue" />
                </div>,
                RHAP_UI.VOLUME,
                <div className="rhap_additional-controls">
                  <LoopButton loop={loop} setLoop={setLoop} />
                </div>,
              ]}
            />
            <div className="rhap_mini-player">
              <MiniPlayer
                progress={progress}
                setTime={setTime}
                Queue={Queue}
                openCloseQueue={openCloseQueue}
                playNextSong={playNextSong}
                playPreviousSong={playPreviousSong}
                Next={Next}
                Prev={Prev}
                paused={paused}
                setPaused={setPaused}
                loop={loop}
                setLoop={setLoop}
              />
            </div>
          </div >
        ) : (
          < div className="player-container">
            <AudioPlayer
              // autoPlay={true}
              // src={""}
              preload="metadata"
              crossOrigin="anonymous"
              ref={(element) => {
                playerRef.current = element && element.audio.current;
              }}
              onEnded={playNextSong}
              onListen={timeListener}
              listenInterval={20}
              showFilledVolume="true"
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              customControlsSection={[
                <img
                  className="rhap_main-controls-button"
                  src={Prev}
                  alt="prev"
                  onClick={playPreviousSong}
                />,
                <div
                  className="rhap_play-pause-button"
                  onClick={() => {
                    setPaused(!paused);
                  }}
                >
                  <PlayButton played={!paused} />
                </div>,
                <img
                  src={Next}
                  alt="next"
                  className="rhap_main-controls-button"
                  onClick={playNextSong}
                />,
                RHAP_UI.CURRENT_TIME,
                <div className="time-sep">/</div>,
                RHAP_UI.DURATION,
                <div className="rhap_song_name">{playedSong.name}</div>,
                <div
                  className="rhap_main-play-list-button"
                  onClick={openCloseQueue}
                >
                  <img src={Queue} alt="queue" />
                  <Link to="/queue" />
                </div>,
                RHAP_UI.VOLUME,
                <div className="rhap_additional-controls">
                  <LoopButton loop={loop} setLoop={setLoop} />
                </div>,
              ]}
            />
            <div className="rhap_mini-player">
              <MiniPlayer
                progress={progress}
                setTime={setTime}
                Queue={Queue}
                openCloseQueue={openCloseQueue}
                playNextSong={playNextSong}
                playPreviousSong={playPreviousSong}
                Next={Next}
                Prev={Prev}
                paused={paused}
                setPaused={setPaused}
                loop={loop}
                setLoop={setLoop}
              />
            </div>
          </div >
        )}</>
      )
      }
    </>
  );
};

export default Player;
