import React, { useEffect, useState } from "react";
import { apiRust } from "../api";
import { toast } from "react-toastify";
import OfferList from "../components/offer/OfferList";

import { useAuth } from "../contexts/AuthContext";

function AdminOfferPage() {
  const { isAdmin } = useAuth();
  const [offerType, setOfferType] = useState("album-artist");
  const [albumArtistOffers, setAlbumArtistOffers] = useState([]);
  const [albumTagOffers, setAlbumTagOffers] = useState([]);
  const [songArtistOffers, setSongArtistOffers] = useState([]);
  const [songTagOffers, setSongTagOffers] = useState([]);
  const fetchOffers = async (type) => {
    try {
      const response = await apiRust.get(`/offer/${offerType}`);
      switch (type) {
        case "album-artist":
          setAlbumArtistOffers(response.data);
          break;
        case "album-tag":
          setAlbumTagOffers(response.data);
          break;
        case "song-artist":
          setSongArtistOffers(response.data);
          break;
        case "song-tag":
          setSongTagOffers(response.data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      toast.error("Error fetching offers.");
    }
  };

  const handleConfirm = async (offer) => {
    try {
      await apiRust.post(`/offer/${offerType}/${offer.cid}`, {
        confirm: true,
      });
      toast.success("Offer confirmed successfully!");
      await fetchOffers(offerType);
    } catch (error) {
      if (error.response) {
        // Обработка ошибок, связанных с ответом от сервера (HTTP-статусы)
        console.log(error.response);
        if (error.response.status === 409) {
          // Ошибка конфликта (например, предложение уже подтверждено)
          toast.error(error.response.data.detail);
        } else if (error.response.status === 404) {
          // Ошибка 404 (например, предложение не найдено)
          toast.error(error.response.data.detail);
        } else {
          // Обработка других ошибок
          toast.error("Server error. Please try again later.");
        }
      } else if (error.request) {
        // Обработка ошибок, связанных с запросом (например, отсутствие ответа от сервера)
        toast.error("No response from the server. Please try again later.");
      } else {
        // Обработка других типов ошибок
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleReject = async (offer) => {
    try {
      await apiRust.delete(`/offer/${offerType}?cid=${offer.cid}`);

      toast.success("Offer rejected successfully!");
      await fetchOffers(offerType);
      // fetchAlbumArtistOffers(); // Обновляем список после отклонения
    } catch (error) {
      toast.error("Error rejecting offer.");
    }
  };
  useEffect(() => {
    fetchOffers(offerType);
  }, [offerType]);

  const handleTypeChange = (type) => {
    setOfferType(type);
  };

  return (
    <>
      {isAdmin ? (
        <div className="offers-page">
          <h2>Offers Page</h2>
          <div className="offers-navigatin">
            <button
              onClick={() => handleTypeChange("album-artist")}
              className={
                offerType === "album-artist" ? "button active" : "button"
              }
            >
              Album Artist
            </button>
            <button
              onClick={() => handleTypeChange("album-tag")}
              className={offerType === "album-tag" ? "button active" : "button"}
            >
              Album Tag
            </button>
            <button
              onClick={() => handleTypeChange("song-artist")}
              className={
                offerType === "song-artist" ? "button active" : "button"
              }
            >
              Song Artist
            </button>
            <button
              onClick={() => handleTypeChange("song-tag")}
              className={offerType === "song-tag" ? "button active" : "button"}
            >
              Song Tag
            </button>
          </div>{" "}
          <OfferList
            offers={
              offerType === "album-artist"
                ? albumArtistOffers
                : offerType === "album-tag"
                  ? albumTagOffers
                  : offerType === "song-artist"
                    ? songArtistOffers
                    : offerType === "song-tag"
                      ? songTagOffers
                      : []
            }
            onConfirm={handleConfirm}
            onReject={handleReject}
          />
        </div>
      ) : (
        <h2>You are not admin!</h2>
      )}
    </>
  );
}
export default AdminOfferPage;
