import React, { useState } from "react";
import st from "./ListItems.module.css";

const MAX_DISPLAY_LENGTH = 20;

const ListItem = ({ items, label }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedItems = items.map((item) => ({
    ...item,
    name: item.name !== undefined ? item.name : item.tag,
  }));

  const resultString = displayedItems.map((item) => item.name).join(", ");
  const shortString = resultString.slice(0, MAX_DISPLAY_LENGTH);

  return (
    <div
      onDoubleClick={toggleExpansion}
      className={st.ListContainer}
      title="Double click to expand/hide"
    >
      {label && <>{`${label}: `}</>}
      {isExpanded || resultString.length <= MAX_DISPLAY_LENGTH ? (
        resultString
      ) : (
        <>
          <span className={st.shortString}>{shortString}</span>{" "}
          <svg
            onClick={toggleExpansion}
            viewBox="-0.5 0 8 8"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="var(--green)"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <title>play [#1001]</title> <desc>Created with Sketch.</desc>{" "}
              <defs> </defs>{" "}
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                {" "}
                <g
                  id="Dribbble-Light-Preview"
                  transform="translate(-427.000000, -3765.000000)"
                  fill="var(--green)"
                >
                  {" "}
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    {" "}
                    <polygon
                      id="play-[#1001]"
                      points="371 3605 371 3613 378 3609"
                    >
                      {" "}
                    </polygon>{" "}
                  </g>{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>{" "}
        </>
      )}
    </div>
  );
};

export default ListItem;
