import React, { useEffect, useState } from "react";
import st from "./AlbumPriview.module.css";
import PlayButton from "../../ui/PlayButton/PlayButton";
import { useNavigate } from "react-router-dom";
import { usePlayer } from "../../contexts/PlayerContext";
import { apiRust } from "../../api";
import { toast } from "react-toastify";

const AlbumPriview = ({ album, albumUpdateCallBack }) => {
  const { replacePlaylist, paused, setPaused, playedSong } = usePlayer();
  const navigate = useNavigate();
  const [localPaused, setLocalPaused] = useState(true);
  const handlePlay = async (e) => {
    e.stopPropagation();
    if (album.songs !== undefined && album.songs.length > 0) {
      const check = songInThisAlbum();
      if (check & !paused) {
        setPaused(true);
      } else if (check) {
        setPaused(!paused);
      } else {
        replacePlaylist(album.songs);
      }
    } else {
      try {
        const response = await apiRust.get(`/albums/${album.cid}`);
        if (response.data.songs.length > 0) {
          replacePlaylist(response.data.songs);
        } else {
          toast.error("This album does not contain any playable tracks");
        }
        albumUpdateCallBack(response.data);
      } catch (error) {
        toast.error("Error geting alum songs");
        console.log(error);
      }
    }
  };
  const songInThisAlbum = () => {
    if (album.songs !== undefined) {
      return album.songs.some((obj) => obj.cid === playedSong.cid);
    }
    return false;
  };
  const checkPlayedSongRelationToThisAlbum = () => {
    if (playedSong) {
      if (songInThisAlbum()) {
        setLocalPaused(paused);
      } else {
        setLocalPaused(true);
      }
    }
  };
  useEffect(() => {
    checkPlayedSongRelationToThisAlbum();
  }, [playedSong, paused]);

  const openAlbum = async () => {
    navigate(`/album/${album.cid}`);
  };
  return (
    <>
      {/* {album.songs.length > 0 && ( */}
      <div className={st.albumPreview} onClick={openAlbum}>
        <div className={st.coverImageContainer}>
          <img
            className={st.coverImage}
            src={album.cover_url}
            alt={album.name}
          />
        </div>
        <strong className={st.albumName}>{album.name}</strong>
        <div className={st.artists}>
          {album.artists.map((artist, index) => (
            <React.Fragment key={artist.cid}>
              {artist.name}
              {index < album.artists.length - 1 && ", "}
            </React.Fragment>
          ))}
        </div>
        <div className={st.tags}>
          {album.tags.map((tag, index) => (
            <React.Fragment key={tag.cid}>
              {tag.tag}
              {index < album.tags.length - 1 && ", "}
            </React.Fragment>
          ))}
        </div>
        <div className={st.playIcon} onClick={handlePlay}>
          <PlayButton played={!localPaused} />
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default AlbumPriview;
