import { createContext, useEffect, useState, useContext } from "react";
import { apiRust } from "../api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const getUser = async () => {
    try {
      const response = await apiRust.get("/auth/me");
      setUser(response.data);
      setIsAdmin(response.data.is_superuser);
      setIsVerified(response.data.is_verified);
      setIsAuthorized(true);
    } catch (error) {
      setUser({});
      setIsAdmin(false);
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    getUser();
  }, [isAuthorized]);

  return (
    <AuthContext.Provider
      value={{
        isAdmin,
        isAuthorized,
        setIsAdmin,
        setIsAuthorized,
        isVerified,
        setIsVerified,
        user,
        setUser,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
