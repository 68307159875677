import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRust } from "../../api";
import { useApp } from "../../contexts/AppContext";
import st from "./SearchBar.module.css";

// Создаем функцию запроса
const getResults = async (value, onResults) => {
  // Отправляем запрос
  await apiRust
    .get("/search", {
      params: {
        value: value,
      },
    })
    .then((response) => {
      // Обрабатываем ответ
      if (response.data.length === 0) {
        toast.warn("No results!", {
          autoClose: 3000,
        });
        console.log("No results!");
      }

      onResults(response.data);
    })
    .catch((error) => {
      // Обрабатываем ошибку
      toast.error("Server error!");
      console.log(error);
    });
};
const SearchBar = () => {
  const [query, setQuery] = useState("");
  const { searchMode, setSearchMode, setAlbums } = useApp();
  const navigate = useNavigate();

  // Выполняем запрос спустя пол секунды после последнего изменения
  useEffect(() => {
    const timer = setTimeout(() => {
      if (query === "") {
        if (searchMode) {
          setAlbums([]);
          setSearchMode(false);
        }
      } else {
        setSearchMode(true);
        getResults(query, setAlbums);
        navigate("/");
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [query, setAlbums]);

  return (
    <input
      type="text"
      className={st.searchInput}
      value={query}
      placeholder="Search"
      onChange={(e) => setQuery(e.target.value)}
    />
  );
};
export default SearchBar;
