import React, { useState } from "react";
import { toast } from "react-toastify";
import { apiRust } from "../../api";

const ChangesForm = ({ changes, song, setVisible, setChangeMode }) => {
  const [messages, setmessages] = useState({
    addedTags: Array(changes.addedTags.length).fill(""),
    removedTags: Array(changes.removedTags.length).fill(""),
    addedArtists: Array(changes.addedArtists.length).fill(""),
    removedArtists: Array(changes.removedArtists.length).fill(""),
  });

  const getLabelValue = (item) => {
    return item.name || item.tag; // Выбираем нужный атрибут в зависимости от типа объекта
  };

  const handlemessageChange = (field, index, value) => {
    setmessages((prevmessages) => ({
      ...prevmessages,
      [field]: prevmessages[field].map((item, i) =>
        i === index ? value : item,
      ),
    }));
  };
  const processTagsAndArtists = async (songCid, changesWithmessages) => {
    // Обработка добавленных и удаленных тегов
    for (const addedTag of changesWithmessages.addedTags) {
      await apiRust.post("/offer/song-tag/", {
        tag: addedTag.tag,
        tag_cid: addedTag.cid,
        message: addedTag.message,
        song_cid: songCid,
        operation: "ADD",
      });
    }

    for (const removedTag of changesWithmessages.removedTags) {
      await apiRust.post("/offer/song-tag/", {
        tag: removedTag.tag,
        tag_cid: removedTag.cid,
        message: removedTag.message,
        song_cid: songCid,
        operation: "DELETE",
      });
    }

    // Обработка добавленных и удаленных артистов
    for (const addedArtist of changesWithmessages.addedArtists) {
      await apiRust.post("/offer/song-artist/", {
        artist: addedArtist.name,
        artist_cid: addedArtist.cid,
        message: addedArtist.message,
        song_cid: songCid,
        operation: "ADD",
      });
    }

    for (const removedArtist of changesWithmessages.removedArtists) {
      await apiRust.post("/offer/song-artist/", {
        artist: removedArtist.name,
        artist_cid: removedArtist.cid,
        message: removedArtist.message,
        song_cid: songCid,
        operation: "DELETE",
      });
    }
  };
  const handleSubmitChanges = async () => {
    // Обрабатываем и отправляем изменения
    try {
      // Обработка добавленных и удаленных тегов и артистов
      // Добавляем комментарии к изменениям
      const changesWithmessages = {
        addedTags: changes.addedTags.map((tag, index) => ({
          ...tag,
          message: messages.addedTags[index],
        })),
        removedTags: changes.removedTags.map((tag, index) => ({
          ...tag,
          message: messages.removedTags[index],
        })),
        addedArtists: changes.addedArtists.map((artist, index) => ({
          ...artist,
          message: messages.addedArtists[index],
        })),
        removedArtists: changes.removedArtists.map((artist, index) => ({
          ...artist,
          message: messages.removedArtists[index],
        })),
      };

      // Обработка добавленных и удаленных тегов и артистов
      await processTagsAndArtists(song.cid, changesWithmessages);
      setVisible(false);
      setChangeMode(false);
      toast.success("Changes requested");
    } catch (error) {
      console.error("Error sending changes:", error);
      toast.error("Error");
    } // await processTagsAndArtists(
    // );

    // Очищаем комментарии после успешной отправки
  };

  return (
    <div className="form-changes">
      <h2>Changes {song.name}</h2>
      {Object.keys(changes).map(
        (field) =>
          // Добавляем условие, чтобы отображать элемент field только если его длина больше 0
          changes[field].length > 0 && (
            <div key={field}>
              {/* Используем условный оператор для выбора более читаемого заголовка */}
              <h3>
                {field === "addedTags"
                  ? "Added Tags"
                  : field === "removedTags"
                    ? "Removed Tags"
                    : field === "addedArtists"
                      ? "Added Artists"
                      : field === "removedArtists"
                        ? "Removed Artists"
                        : field}
              </h3>
              {changes[field].map((item, index) => (
                <div key={index} className="form-list-obj">
                  <label htmlFor={`${field}-${index}`}>
                    {getLabelValue(item)}
                  </label>
                  <div>
                    <textarea
                      className="input"
                      type="text"
                      id={`${field}-${index}`}
                      value={messages[field][index]}
                      placeholder="justify your choice"
                      onChange={(e) =>
                        handlemessageChange(field, index, e.target.value)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          ),
      )}
      <button className="button" onClick={handleSubmitChanges}>
        Confirm everything and send
      </button>
    </div>
  );
};

export default ChangesForm;
